<template>
  <div/>
</template>

<script>
export default {
    name: "LanguageHandler",
    created: function(){
        let code = this.code
        if(this.code){
            this.$store.commit('language',`${this.code}`)
            this.$router.push({path:`/events/${code}`})
        }
    },
    computed: {
        code: function(){
            return `${this.$route.params.code}-ca`
        }
    }
}
</script>

<style>

</style>